<template>
   <form
      novalidate
      v-bind="$attrs"
      class="obi-form"
      v-on="$listeners"
      autocomplete="off"
      :class="{ 'obi-form--loading': loading }"
   >
      <slot />
   </form>
</template>

<script>
export default {
   name: 'ObiForm',
   props: {
      loading: {
         type: Boolean,
         default: false,
      },
   },
};
</script>
<style lang="scss" scoped>
.obi-form {
   &.obi-form--loading {
      overflow: hidden;
      position: relative;

      &::before {
         top: 0;
         left: 0;
         content: '';
         width: 100%;
         height: 100%;
         z-index: 999;
         display: block;
         position: absolute;
         background-color: #ffffffaa;
      }
   }
}
</style>
